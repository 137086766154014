import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import './styles.scss';

export interface IDateFilter {
  className?: string;
  dateFormat?: string;
  disabled?: boolean;
  placeholder?: string;
  label?: string | JSX.Element;
  showMonthDropdown?: boolean;
  showTimeSelect?: boolean;
  showYearDropdown?: boolean;
  value?: Date | null;
  onChange?: (value: Date) => void;
}

const DateFilter: FC<IDateFilter> = ({
  className,
  dateFormat = 'dd.MM.yyyy',
  label,
  placeholder,
  showMonthDropdown = true,
  showYearDropdown = true,
  value,
  onChange,
  ...rest
}) => {
  return (
    <div className='date-picker-container'>
      {label ? <label className='label'>{label}</label> : null}
      <DatePicker
        className={className ? `field ${className}` : 'field'}
        dateFormat={dateFormat}
        dateFormatCalendar='MMMM'
        dropdownMode='select'
        placeholderText={placeholder}
        shouldCloseOnSelect={true}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        selected={value}
        onChange={(date: Date) => onChange && onChange(date)}
        {...rest}
      />
    </div>
  );
};

export default DateFilter;
