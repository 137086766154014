import React, { FC, useEffect, useState } from 'react';
import { useField } from 'informed';
import DatePicker from 'react-datepicker';
import './styles.scss';

export interface IDateField {
  className?: string;
  dateFormat?: string;
  defaultValue?: Date;
  disabled?: boolean;
  dropdownMode?: 'select' | 'scroll';
  placeholder?: string;
  label?: string | JSX.Element;
  maxDate?: Date;
  maxTime?: Date;
  minDate?: Date;
  minTime?: Date;
  name: string;
  showMonthDropdown?: boolean;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  showYearDropdown?: boolean;
  timeIntervals?: number;
  timeFormat?: string;
  onChange?: (value: Date) => void;
  validate?: (value: unknown) => void | string;
}

const DateField: FC<IDateField> = ({
  className,
  dateFormat = 'dd.MM.yyyy',
  defaultValue,
  dropdownMode = 'select',
  label,
  placeholder,
  name,
  showMonthDropdown = true,
  showYearDropdown = true,
  onChange,
  validate,
  ...rest
}) => {
  const {
    fieldState: { error, value },
    fieldApi: { setTouched, setValue, validate: validateField, reset },
    render,
  } = useField<IDateField, Date>({ name, validate });

  const [dateValue, setDateValue] = useState<Date | null>(value instanceof Date ? value : null);
  const selectedValue = dateValue ? dateValue : undefined;

  useEffect(() => {
    if (defaultValue) {
      setDateValue(defaultValue);
      setValue(defaultValue);
    }
  }, []);

  return render(
    <div className={className ? `date-picker-field ${className}` : 'date-picker-field'}>
      {label ? <label className='date-picker-label'>{label}</label> : null}
      <DatePicker
        className='date-picker'
        dateFormat={dateFormat}
        dateFormatCalendar='MMMM'
        dropdownMode={dropdownMode}
        openToDate={selectedValue}
        placeholderText={placeholder}
        selected={dateValue}
        shouldCloseOnSelect={true}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        onChangeRaw={(e) => e.preventDefault()}
        onFocus={() => setTouched(true)}
        onChange={(date: Date) => {
          if (date) {
            setValue(date);
            setDateValue(date);
            validateField();
            onChange?.(date);
          } else {
            setDateValue(null);
            reset();
          }
        }}
        {...rest}
      />
      {error ? <p className='date-picker-error'>{error as string}</p> : null}
    </div>
  );
};

export default DateField;
