/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { Spin, message } from 'antd';
import { Button, DetailsContainer, PageTitle } from 'hbh-ui-modules';
import { useReactToPrint } from 'react-to-print';
import './styles.scss';

import { useRepository } from '@context';
import { useFacility } from '@common/hooks';
import { Serializer } from '@common/utils';

import { IBookingDetailsPage, IBookingRoute, IBookingUpdate } from '@common/interfaces';
import { BookingEdit, Modal } from '@components';

const BookingDetailsPage: FC = () => {
  const printRef = useRef(null);

  const facilityId = +localStorage.getItem('facility')!;

  const { id: bookingId } = useParams<{ id: string }>();
  const { bookingRepository } = useRepository();
  const { facility } = useFacility();
  const navigate = useNavigate();

  const [booking, setBooking] = useState<IBookingDetailsPage>();
  const [editLeg, setEditLeg] = useState<IBookingRoute | null>(null);

  const {
    data: bookingDetails,
    isLoading,
    refetch: getBookingDetails,
  } = useQuery(`request-${bookingId}`, () => bookingRepository.getBookingDetails(facilityId, bookingId!), {
    refetchOnWindowFocus: false,
    retry: 2,
    onError: (err: any) => {
      if (err.response.status === 404) {
        message.error('Request with such id was not found');

        setTimeout(() => {
          navigate(-1);
        }, 1000);
      }
    },
  });

  const { mutateAsync: updateBooking } = useMutation<unknown, void, { bookingId: string; data: IBookingUpdate }>(
    'update-booking',
    ({ bookingId, data }) => bookingRepository.updateBooking(facilityId, bookingId, data)
  );

  const onPrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    if (bookingDetails) {
      setBooking(Serializer.bookingDetailsToPage(bookingDetails));
    }
  }, [bookingDetails]);

  const getBookingStateBtn = (booking: IBookingDetailsPage) => {
    const { state } = booking;
    let btn;

    switch (state) {
      case 'cancelled_by_fp':
        btn = <Button className='btn btn-cancelled' text='Cancel by FP' variant='transparent' />;
        break;
      case 'completed':
        btn = <Button className='btn btn-completed' text='Completed' variant='transparent' />;
        break;
      case 'in_progress':
        btn = <Button className='btn btn-in-progress' text='In Progress' variant='transparent' />;
        break;
      case 'not_processed':
        btn = <Button className='btn btn-not-processed' text='To be processed' variant='danger' />;
        break;
      default:
        btn = <Button className='btn btn-default' text={state} variant='transparent' />;
        break;
    }

    return btn;
  };

  const submitBookingUpdate = async (data: IBookingUpdate) => {
    if (booking) {
      const response = await updateBooking({ bookingId: booking.id, data: data });

      if (response) {
        message.success('Booking was successfully updated');
        setEditLeg(null);
        getBookingDetails();
      }
    }
  };

  const code = booking?.referenceCode ? booking?.referenceCode : '';
  const documents = booking?.travelers[0]?.documents?.length ? booking?.travelers[0]?.documents[0] : null;

  return (
    <>
      <PageTitle className='booking-details-title' title={`Travel request detail, ${facility.country} #${code}`} />
      <Spin spinning={isLoading}>
        <section className='booking-details-container hbh-container' ref={printRef}>
          <div className='title'>
            <h3>Requestor Details</h3>
            <div>
              <Button className='btn btn-print' text='Print PDF' variant='transparent' onClick={onPrint} />
              {booking ? getBookingStateBtn(booking) : null}
            </div>
          </div>

          <DetailsContainer
            className='asset-details'
            items={[
              { label: 'Name', content: booking?.travelers[0]?.name ?? '', className: 'row-1-col-1' },
              { label: 'Surname', content: booking?.travelers[0]?.surname ?? '', className: 'row-1-col-2' },
              { label: 'Email', content: booking?.travelers[0]?.email ?? '', className: 'row-1-col-3' },
              { label: 'Phone number', content: booking?.travelers[0]?.phone_number ?? '', className: 'row-2-col-1' },
              { label: 'Index number', content: booking?.travelers[0]?.index_number ?? '', className: 'row-2-col-2' },
              {
                label: 'Email of supervisor',
                content: booking?.travelers[0]?.email_of_supervisor ?? '',
                className: 'row-2-col-3',
              },
              {
                label: 'Nationality',
                content: documents?.nationality ?? '',
                className: 'row-3-col-1',
              },
              {
                label: 'Document type',
                content: documents?.document_type_display ?? '',
                className: 'row-3-col-2',
              },
              {
                label: 'Document number',
                content: documents?.document_number ?? '',
                className: 'row-3-col-3',
              },
              {
                label: 'Issue date of travel document',
                content: documents?.issue_date ?? '',
                className: 'row-4-col-1',
              },
              {
                label: 'Expiry date of travel document',
                content: documents?.expiry_date ?? '',
                className: 'row-4-col-2',
              },
              {
                label: 'Remarks',
                content: booking?.remarks ?? '',
                className: 'row-4-col-3 line-break',
              },
            ]}
            divider
          />

          {booking && booking.routes?.length > 0
            ? booking?.routes?.map((route: IBookingRoute, idx: number) => (
                <div className='booking-route' key={idx}>
                  <DetailsContainer
                    className='leg-details'
                    divider
                    items={[
                      {
                        label: '',
                        content: '',
                        className: 'travel-info',
                        altContent: (
                          <div className='info'>
                            <div className='travel-type'>{route.travel_type_display}</div>
                            <h4>Transport Support</h4>
                            <div className='transport'>{route.mode_of_transport_display}</div>
                          </div>
                        ),
                      },
                      { label: 'Departure Date', content: route.departure_date ?? '', className: 'departure-date' },
                      { label: 'Departure Time', content: route.departure_time ?? '', className: 'departure-time' },
                      {
                        label: 'Departure Country',
                        content: route.departure_country ?? '',
                        className: 'departure-country',
                      },
                      { label: 'Departure City', content: route.departure_city ?? '', className: 'departure-city' },
                      {
                        label: 'Transport Support',
                        content: route.managed_by_admin ? 'Yes' : 'No',
                        className: 'transport-support',
                      },
                      { label: 'Arrival Date', content: route.arrival_date ?? '', className: 'arrival-date' },
                      { label: 'Arrival Time', content: route.arrival_time ?? '', className: 'arrival-time' },
                      { label: 'Arrival Country', content: route.arrival_country ?? '', className: 'arrival-country' },
                      { label: 'Arrival City', content: route.arrival_city ?? '', className: 'arrival-city' },
                    ]}
                    title={`${route.leg_number} Details`}
                  />

                  <Button className='btn-edit-leg' text='Edit' variant='primary' onClick={() => setEditLeg(route)} />
                </div>
              ))
            : null}

          {booking ? (
            <div className='footer'>
              {getBookingStateBtn(booking)}
              <Button
                className='btn-back'
                text='Back to List'
                variant='primary'
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          ) : null}
        </section>
      </Spin>

      {editLeg && (
        <Modal title={`Edit ${editLeg?.leg_number}`} visible={!!editLeg} onClose={() => setEditLeg(null)}>
          <BookingEdit route={editLeg} onSubmit={submitBookingUpdate} />
        </Modal>
      )}
    </>
  );
};

export default BookingDetailsPage;
