import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { MainMenu } from 'hbh-ui-modules';
import axios from 'axios';
import './styles.scss';

import { ReactComponent as IconTravelRequests } from '@assets/svg/icon_travel.svg';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';

const MainMenuPage: FC = () => {
  const navigate = useNavigate();

  const [maintenanceModeActive, setMaintenanceModeActive] = useState<boolean>(false);
  const [maintenanceModeText, setMaintenanceModeText] = useState<string>();

  const getMaintenanceMode = async (): Promise<void> => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_HBH_API}/v2/core/feature-flags/`, {
        headers: {
          'X-Subscription-Token': process.env.REACT_APP_SUBSCRIPTION_TOKEN || '',
        },
      });

      const maintenanceMode = response.data?.switches?.maintenance_mode_active;

      if (maintenanceMode) {
        setMaintenanceModeActive(maintenanceMode.is_active);
        setMaintenanceModeText(maintenanceMode.note);
      }
    } catch (e: any) {
      console.error(e.response.data);
    }
  };

  useEffect(() => {
    getMaintenanceMode();
  }, []);

  const menuButtons = [
    {
      className: 'btn-requests',
      icon: IconTravelRequests,
      label: 'Travel Requests',
      onClick: () => navigate('/requests'),
    },
  ];

  return (
    <>
      {maintenanceModeActive ? <div className='maintenance-disclaimer'>{maintenanceModeText}</div> : null}
      <MainMenu logo={Logo} menuButtons={menuButtons} title='Ukraine Emergency Digital Office' />
    </>
  );
};

export default MainMenuPage;
