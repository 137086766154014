export const ENV = {
  DEV: 'dev',
  QA: 'qa',
  PROD: 'prod',
};

export const DATE_FORMAT = {
  DATEPICKER: 'dd MMM yyyy',
};

export const SUPPORT_EMAIL = process.env.REACT_APP_EMAIL_SUPPORT ?? 'global.accomodation@wfp.org';