import React, { FC } from 'react';
import './styles.scss';

const RedirectPage: FC = () => {
  return (
    <div className='page-redirect'>
      <div className='redirect-box'>
        <h3>
          Ops, seems like you opened this page from the source other than Humanitarian Booking Hub. Please, follow the
          link below.
        </h3>
        <a href={`${process.env.REACT_APP_HBH_DO_URL}`}>Click here</a>
      </div>
    </div>
  );
};

export default RedirectPage;
