import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Spin, Button } from 'antd';
import { useAuth } from 'hbh-ui-modules';
import './styles.scss';

import { useParamsParse } from '@common/hooks';

const SignInPage: FC = () => {
  const navigate = useNavigate();
  const isParsed = useParamsParse();
  const { firstLoginAttempt, authorize } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (firstLoginAttempt) {
      setIsLoading(true);

      if (isParsed) {
        authorize?.();
      } else {
        navigate('redirect');
      }
    }
  }, [firstLoginAttempt]);

  return (
    <section className='page-login'>
      <div className='login'>
        <div className='row'>
          <span className='login-greeting'>Ukraine Emergency Digital Office</span>
        </div>
        <Spin className='login-loading' size='large' spinning={isLoading}></Spin>
        {!firstLoginAttempt && (
          <Button
            className='login-link'
            type='link'
            onClick={() => {
              setIsLoading(true);
              authorize?.();
            }}
          >
            Sign in
          </Button>
        )}
      </div>
    </section>
  );
};

export default SignInPage;
