import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import { IBookingRepository, BookingListParams, IBookingUpdate } from '@common/interfaces';

class BookingRepository extends AxiosRepository implements IBookingRepository {
  constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
  }

  private createBookingParams(params?: BookingListParams) {
    const searchParams = new URLSearchParams();

    if (params?.departure_city) searchParams.append('departure_city', String(params.departure_city));
    if (params?.departure_country) searchParams.append('departure_country', String(params.departure_country));
    if (params?.departure_date) searchParams.append('departure_date', String(params.departure_date));
    if (params?.limit) searchParams.append('limit', String(params.limit));
    if (params?.offset) searchParams.append('offset', String(params.offset));
    if (params?.ordering) searchParams.append('ordering', params.ordering);
    if (params?.request_date) searchParams.append('request_date', String(params.request_date));
    if (params?.show) searchParams.append('show', params.show);
    if (params?.state) searchParams.append('state', params.state);

    return searchParams;
  }

  async getBookings(facilityId: number, params?: BookingListParams) {
    const searchParams = this.createBookingParams(params);

    const { data } = await this.get(`/facilities/${facilityId}/bookings/?${searchParams.toString()}`);

    return data;
  }

  async getBookingDetails(facilityId: number, bookingId: string) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/${bookingId}/`);
    
    return data;
  }

  async getFilters(facilityId: number) {
    const { data } = await this.get(`/facilities/${facilityId}/bookings/filters/`);

    return data;
  }

  async exportBookings(facilityId: number, params?: BookingListParams) {
    const searchParams = this.createBookingParams(params);

    const { data } = await this.get(`/facilities/${facilityId}/bookings/export/?${searchParams.toString()}`, {
      responseType: 'blob',
    });

    return new File([data], 'booking_list.xls');
  }

  async updateBooking(facilityId: number, bookingId: string, updateData: IBookingUpdate) {
    const { data } = await this.patch(`/facilities/${facilityId}/bookings/${bookingId}/`, updateData);
    return data;
  }
}

export default BookingRepository;
