import { AxiosInstance } from 'axios';

class AxiosRepository {
  public constructor(private axiosClient: AxiosInstance) {}

  public get = this.axiosClient.get;
  public patch = this.axiosClient.patch;
  public post = this.axiosClient.post;
  public delete = this.axiosClient.delete;
}

export default AxiosRepository;
