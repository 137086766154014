import React, { FC, useEffect, useRef } from 'react';
import './styles.scss';

import { CloseOutlined } from '@ant-design/icons';

interface IModal {
  title?: string;
  visible?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}

const Modal: FC<IModal> = ({ children, title, visible = false, onClose, onOpen }) => {
  const mounted = useRef(false);

  const onBackgroundClickHandler = (): void => {
    onClose && onClose();
  };

  const onCloseButtonClicked = (): void => {
    onClose && onClose();
  };

  useEffect(() => {
    // This allow to not run useEffect logic at first load
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    visible ? onOpen && onOpen() : onClose && onClose();
  }, [visible]);

  return (
    <>
      {visible && (
        <div className='modal'>
          <div className='modal-bg' onClick={onBackgroundClickHandler} />
          <div className='modal-content'>
            {title && (
              <div className='modal-header'>
                {title}
                <div className='modal-btn-close' onClick={onCloseButtonClicked}>
                  <CloseOutlined />
                </div>
              </div>
            )}
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
