import React, { FC, useEffect } from 'react';
import { Switch as AntdSwitch } from 'antd';
import { useField } from 'informed';
import './styles.scss';

export interface ISwitch {
  className?: string;
  defaultValue?: boolean;
  label?: string;
  name: string;
  text?: string;
}

const Switch: FC<ISwitch> = ({ className, defaultValue, label, name, text = 'Yes' }) => {
  const {
    fieldState: { value },
    fieldApi: { setValue },
  } = useField<ISwitch, boolean>({ name });

  const onChange = (checked: boolean) => {
    setValue(checked);
  };

  useEffect(() => {
    setValue(defaultValue ?? true);
  }, []);

  return (
    <div className={className ? `switch ${className}` : 'switch'}>
      {label && <label className='switch-label'>{label}</label>}
      <AntdSwitch checked={value as boolean} onChange={onChange} />
      {<span className='switch-text'>{text}</span>}
    </div>
  );
};

export default Switch;
