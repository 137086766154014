import React, { createContext, FC, ReactNode, useContext } from 'react';
import { axios } from '@common/utils';
import { BookingRepository, FacilityRepository } from '@common/repositories';

const repositories = {
  bookingRepository: new BookingRepository(axios),
  facilityRepository: new FacilityRepository(axios),
};

const RepositoryContext = createContext(repositories);

interface IRepositoryProvider {
  children: ReactNode;
}

const RepositoryProvider: FC<IRepositoryProvider> = ({ children }) => {
  return <RepositoryContext.Provider value={repositories}>{children}</RepositoryContext.Provider>;
};

const useRepository = () => useContext(RepositoryContext);

export { RepositoryProvider, useRepository };
