import React, { FC } from 'react';
import { SUPPORT_EMAIL } from '@common/constants';
import './styles.scss';

const NoAccessPage: FC = () => {
  return (
    <main className='page-access'>
      <div className='container'>
        <h3 className='message'>
          Sorry, you don&apos;t have access to this page. If you are sure you have valid permissions please contact
          support: <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        </h3>
      </div>
    </main>
  );
};

export default NoAccessPage;
