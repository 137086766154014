import { AxiosInstance } from 'axios';
import AxiosRepository from './axios.repository';
import { IFacilityRepository } from '@common/interfaces';

class FacilityRepository extends AxiosRepository implements IFacilityRepository {
  constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
  }

  async getFacility(facilityId: number) {
    const { data } = await this.get(`/facilities/${facilityId}/`);
    
    return data;
  }
}

export default FacilityRepository;
