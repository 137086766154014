import React, { FC } from 'react';
import { Routes, Route, useMatch, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Footer, Header, NavigationPage, ICrumb, useAuth } from 'hbh-ui-modules';

import { RepositoryProvider } from '@context';
import { CallbackPage, ErrorPage, NoAccessPage, NotFoundPage, RedirectPage, SignInPage } from '@pages';
import routes from './routes';
import packageJson from '../package.json';

const queryClient = new QueryClient();

const RouterElement: FC<{ route: ICrumb }> = ({ route }) => {
  const match = useMatch(route.path);

  return (
    <NavigationPage
      allRoutes={routes}
      givenRoute={route}
      routePath={match?.pattern.path ?? ''}
      routeParams={match?.params}
    />
  );
};

const App: FC = () => {
  const navigate = useNavigate();
  const { hbhPermissionGranted: isAuthenticated, userName, logout } = useAuth();

  const logoutHandler = () => {
    logout?.();
    navigate('/', { replace: true });
  };

  return (
    <QueryClientProvider client={queryClient}>
      <RepositoryProvider>
        {isAuthenticated ? (
          <Header username={userName ?? ''} serviceName='Travel Request' logout={logoutHandler} />
        ) : null}
        <Routes>
          {isAuthenticated ? (
            <>
              {routes.map((route: ICrumb, i: number) => (
                <Route element={<RouterElement route={route} />} key={i} path={route.path} />
              ))}
              <Route path='callback/*' element={<CallbackPage />} />
              <Route path='redirect' element={<RedirectPage />} />
            </>
          ) : (
            <>
              <Route index element={<SignInPage />} />
              <Route path='redirect' element={<RedirectPage />} />
              <Route path='callback/*' element={<CallbackPage />} />
              <Route path='forbidden' element={<NoAccessPage />} />
              <Route path='error' element={<ErrorPage />} />
            </>
          )}
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
        {isAuthenticated ? <Footer releaseVersion={`v${packageJson.version ?? ''}`} /> : null}
      </RepositoryProvider>
    </QueryClientProvider>
  );
};

export default App;
