import moment from 'moment';
import {
  IBooking,
  IBookingDetails,
  IBookingDetailsPage,
  IBookingRoute,
  IBookingToTable,
  IBookingTraveler,
  IBookingTravelerDocument,
  IFacility,
  IFacilityMapped,
} from '@common/interfaces';

class Serializer {
  public static bookingToTable(data: IBooking): IBookingToTable {
    return {
      allowedTransitions: data.allowed_transitions,
      arrivalCountry: data.arrival_country,
      arrivalDate: data.arrival_date ? moment(data.arrival_date).format('DD/MM/YYYY') : '',
      departureCountry: data.departure_country,
      departureDate: data.departure_date ? moment(data.departure_date).format('DD/MM/YYYY') : '',
      facilityId: data.facility_id_on_hbh,
      id: data.uuid,
      lastName: data.last_name,
      name: data.name,
      numberOfLegs: data.number_of_legs,
      requestDate: data.requested ? moment(data.requested).format('DD/MM/YYYY') : '',
      routes: data.routes?.map((route: IBookingRoute, idx: number) => ({
        ...route,
        arrival_date: route.arrival_date ? moment(route.arrival_date).format('DD/MM/YYYY') : '',
        departure_date: route.departure_date ? moment(route.departure_date).format('DD/MM/YYYY') : '',
        id: `${data.uuid}${idx}`,
        last_name: data.last_name,
        leg_number: `Leg ${idx + 1}`,
        name: data.name,
      })),
      state: data.state,
      travelType: data.travel_type,
    };
  }

  public static bookingDetailsToPage(data: IBookingDetails): IBookingDetailsPage {
    return {
      facilityId: data.facility_id_on_hbh,
      id: data.uuid,
      referenceCode: data.reference_code ? data.reference_code : '',
      remarks: data.remarks,
      routes: data.routes?.map((route: IBookingRoute, idx: number) => ({
        ...route,
        arrival_date: route.arrival_date ? moment(route.arrival_date).format('DD/MM/YYYY') : '',
        departure_date: route.departure_date ? moment(route.departure_date).format('DD/MM/YYYY') : '',
        leg_number: `Leg ${idx + 1}`,
      })),
      state: data.state,
      travelers: data.travelers?.map((traveler: IBookingTraveler) => ({
        ...traveler,
        documents: traveler.documents?.length ? traveler.documents.map((doc: IBookingTravelerDocument) => ({
          ...doc,
          expiry_date: doc.expiry_date ? moment(doc.expiry_date).format('DD/MM/YYYY') : '',
          issue_date: doc.issue_date ? moment(doc.issue_date).format('DD/MM/YYYY') : '',
        })) : [],
      })),
    };
  }

  public static formatFacility(facility: IFacility): IFacilityMapped {
    return {
      name: facility.name,
      agency: facility.agency,
      city: facility.city_name,
      country: facility.country_name,
      email: facility.email,
    };
  }
}

export default Serializer;
