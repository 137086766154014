import { useEffect, useState } from 'react';
import { useRepository } from '@context';
import { useQuery } from 'react-query';
import { IFacilityMapped } from '@common/interfaces';
import { Serializer } from '@common/utils';

let savedFacilityId: string;

interface IFacilityResponse {
  facility: IFacilityMapped;
  isLoading: boolean;
}

const initialState = {
  agency: '',
  city: '',
  country: '',
  email: '',
  name: '',
};

const useFacility = (): IFacilityResponse => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const facilityId = localStorage.getItem('facility')!;
  const { facilityRepository } = useRepository();

  const [facility, setFacility] = useState<IFacilityMapped>(initialState);

  const {
    data: facilityData,
    refetch,
    isLoading,
  } = useQuery('get-facility', () => facilityRepository.getFacility(+facilityId), {
    refetchOnWindowFocus: false,
    enabled: facility.city.length === 0 || facility.country.length === 0,
  });

  useEffect(() => {
    if (facilityData) {
      setFacility(Serializer.formatFacility(facilityData));
    }
  }, [facilityData]);

  useEffect(() => {
    if (savedFacilityId && savedFacilityId !== facilityId) {
      refetch();
    } else if (facilityId) {
      savedFacilityId = facilityId;
    }
  }, [facilityId]);

  return { facility, isLoading };
};

export default useFacility;
