import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { Dropdown as Select, Menu, message } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { Button, Dropdown, PageTitle, Pagination, Table } from 'hbh-ui-modules';
import fileDownload from 'js-file-download';
import moment from 'moment';
import './styles.scss';

import { useRepository } from '@context';
import { useFacility } from '@common/hooks';
import { IBookingToTable, IBookingUpdate, IDropdownOption, IFilters } from '@common/interfaces';
import { IBookingState } from '@common/types';
import { Serializer } from '@common/utils';

import { BookingLegs, DateFilter } from '@components';
import { ReactComponent as IconExpand } from '@assets/svg/icon_dropdown_arrow.svg';

const BookingListPage: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const facilityId = +localStorage.getItem('facility')!;
  const {
    facility: { city, country, agency },
  } = useFacility();

  const { bookingRepository } = useRepository();

  const [bookingNotProcessed, setBookingNotProcessed] = useState<IBookingToTable[]>([]);
  const [bookingNotProcessedTotalItems, setBookingNotProcessedTotalItems] = useState<number>(1);
  const [bookingNotProcessedSelectedPage, setBookingNotProcessedSelectedPage] = useState<number>(1);
  const [bookingNotProcessedPageSize, setBookingNotProcessedPageSize] = useState<number>(10);
  const [bookingNotProcessedColumnOrder, setBookingNotProcessedColumnOrder] = useState<string>('');

  const [bookingProcessed, setBookingProcessed] = useState<IBookingToTable[]>([]);
  const [bookingProcessedTotalItems, setBookingProcessedTotalItems] = useState<number>(1);
  const [bookingProcessedSelectedPage, setBookingProcessedSelectedPage] = useState<number>(1);
  const [bookingProcessedPageSize, setBookingProcessedPageSize] = useState<number>(10);
  const [bookingProcessedColumnOrder, setBookingProcessedColumnOrder] = useState<string>('');

  const [filterDepartureCity, setFilterDepartureCity] = useState<IDropdownOption | null>();
  const [filterDepartureСountry, setFilterDepartureСountry] = useState<IDropdownOption | null>();
  const [filterDepartureDate, setFilterDepartureDate] = useState<Date | null>();
  const [filterRequestDate, setFilterRequestDate] = useState<Date | null>();
  const [filterStatus, setFilterStatus] = useState<IDropdownOption | null>();

  const {
    data: bookingNotProcessedData,
    isLoading: isBookingNotProcessedLoading,
    refetch: getNotProcessedBookings,
  } = useQuery(
    [
      'booking-not-processed',
      bookingNotProcessedColumnOrder,
      bookingNotProcessedSelectedPage,
      bookingNotProcessedPageSize,
      filterDepartureCity,
      filterDepartureСountry,
      filterDepartureDate,
      filterRequestDate,
      filterStatus,
    ],
    () =>
      bookingRepository.getBookings(facilityId, {
        departure_city: filterDepartureCity?.value,
        departure_country: filterDepartureСountry?.value,
        departure_date: filterDepartureDate ? moment(filterDepartureDate).format('YYYY-MM-DD') : '',
        limit: bookingNotProcessedPageSize,
        offset: (bookingNotProcessedSelectedPage - 1) * 10,
        ordering: bookingNotProcessedColumnOrder,
        request_date: filterRequestDate ? moment(filterRequestDate).format('YYYY-MM-DD') : '',
        show: 'not_processed',
        state: filterStatus?.value,
      }),
    { refetchOnWindowFocus: false }
  );

  const {
    data: bookingProcessedData,
    isLoading: isBookingProcessedLoading,
    refetch: getProcessedBookings,
  } = useQuery(
    [
      'booking-processed',
      bookingProcessedColumnOrder,
      bookingProcessedSelectedPage,
      bookingProcessedPageSize,
      filterDepartureCity,
      filterDepartureСountry,
      filterDepartureDate,
      filterRequestDate,
      filterStatus,
    ],
    () =>
      bookingRepository.getBookings(facilityId, {
        departure_city: filterDepartureCity?.value,
        departure_country: filterDepartureСountry?.value,
        departure_date: filterDepartureDate ? moment(filterDepartureDate).format('YYYY-MM-DD') : '',
        limit: bookingProcessedPageSize,
        offset: (bookingProcessedSelectedPage - 1) * 10,
        ordering: bookingProcessedColumnOrder,
        request_date: filterRequestDate ? moment(filterRequestDate).format('YYYY-MM-DD') : '',
        show: 'processed',
        state: filterStatus?.value,
      }),
    { refetchOnWindowFocus: false }
  );

  const { data: filtersData, refetch: getFilters } = useQuery<unknown, void, IFilters>(
    ['booking-filters'],
    () => bookingRepository.getFilters(facilityId),
    { refetchOnWindowFocus: false }
  );

  const { mutate: getExports, isLoading: exportsLoading } = useMutation<unknown, void>(
    ['export-bookings'],
    () =>
      bookingRepository.exportBookings(facilityId, {
        departure_city: filterDepartureCity?.value,
        departure_country: filterDepartureСountry?.value,
        departure_date: filterDepartureDate ? moment(filterDepartureDate).format('YYYY-MM-DD') : '',
        ordering: 'created',
        request_date: filterRequestDate ? moment(filterRequestDate).format('YYYY-MM-DD') : '',
        state: filterStatus?.value,
      }),
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSuccess: (data: any) => {
        fileDownload(data, `${agency}_${country}_${city}_${moment().format('YYYY-MM-DD')}_booking_list.xls`);
      },
    }
  );

  const { mutateAsync: updateBooking } = useMutation<unknown, void, { bookingId: string; data: IBookingUpdate }>(
    'update-booking',
    ({ bookingId, data }) => bookingRepository.updateBooking(facilityId, bookingId, data)
  );

  const getBookingStateBtn = (record: IBookingToTable): JSX.Element | null => {
    const { state } = record;

    let btn;

    switch (state) {
      case 'cancelled_by_fp':
        btn = <Button className='btn btn-cancelled' text='Cancel by FP' variant='transparent' />;
        break;
      case 'completed':
        btn = <Button className='btn btn-completed' text='Сompleted' variant='submit' />;
        break;
      case 'in_progress':
        btn = <Button className='btn btn-in-progress' text='In Progress' variant='transparent' />;
        break;
      case 'not_processed':
        btn = <Button className='btn btn-not-processed' text='To be processed' variant='danger' />;
        break;
      default:
        btn = <Button className='btn btn-default' text={state} variant='transparent' />;
        break;
    }

    return <Select overlay={getDropdownMenu(record)}>{btn}</Select>;
  };

  const getDropdownMenu = (booking: IBookingToTable) => {
    return (
      <Menu onClick={({ key }) => onStatusClickedHandler(booking, key as IBookingState)}>
        {booking.allowedTransitions?.map((status) => (
          <Menu.Item className='menu-item' key={status.value}>
            {status.label}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  const onStatusClickedHandler = async (booking: IBookingToTable, selectedState: IBookingState) => {
    const response = await updateBooking({ bookingId: booking.id, data: { state: selectedState } });

    if (response) {
      message.success('Booking was successfully updated');
      getNotProcessedBookings();
      getProcessedBookings();
      getFilters();
    }
  };

  const clearFilters = () => {
    setFilterStatus(null);
    setFilterRequestDate(null);
    setFilterDepartureDate(null);
    setFilterDepartureСountry(null);
    setFilterDepartureCity(null);
  };

  useEffect(() => {
    if (bookingNotProcessedData) {
      setBookingNotProcessed(bookingNotProcessedData.results.map(Serializer.bookingToTable));
      setBookingNotProcessedTotalItems(bookingNotProcessedData.count);
    }

    if (bookingProcessedData) {
      setBookingProcessed(bookingProcessedData.results.map(Serializer.bookingToTable));
      setBookingProcessedTotalItems(bookingProcessedData.count);
    }
  }, [bookingProcessedData, bookingNotProcessedData]);

  const columns = [
    {
      dataIndex: 'requestDate',
      key: 'created',
      title: 'Request Date',
      sortDirections: ['descend', 'ascend'] as SortOrder[],
      sorter: true,
    },
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Name',
    },
    {
      dataIndex: 'lastName',
      key: 'lastName',
      title: 'Last Name',
    },
    {
      dataIndex: 'travelType',
      key: 'travelType',
      title: 'Travel Type',
    },
    {
      dataIndex: 'departureCountry',
      key: 'departureCountry',
      title: 'Departure Country',
    },
    {
      dataIndex: 'departureDate',
      key: 'departureDate',
      title: 'Departure Date',
    },
    {
      dataIndex: 'arrivalCountry',
      key: 'arrivalCountry',
      title: 'Arrival Country',
    },
    {
      dataIndex: 'arrivalDate',
      key: 'arrivalDate',
      title: 'Arrival Date',
    },
    {
      dataIndex: 'numberOfLegs',
      key: 'numberOfLegs',
      title: 'Number Of legs',
    },
    {
      key: 'state',
      title: 'Status',
      render: (record: IBookingToTable) => getBookingStateBtn(record),
    },
    {
      key: 'details',
      title: '',
      render: (record: IBookingToTable) => (
        <Link className='details-link' to={record.id}>
          Full Details &gt;&gt;
        </Link>
      ),
    },
  ];

  return (
    <main className='container booking-list'>
      <PageTitle title={`Travel Request, ${country} | ${agency}`} />

      <h3>Filters</h3>
      <section className='filters'>
        <Dropdown
          className='dropdown'
          options={filtersData?.states.filter((state) => state.value !== 'not_processed') ?? []}
          placeholder='All Status'
          value={filterStatus}
          onChange={setFilterStatus}
        />

        <DateFilter placeholder='Request date' value={filterRequestDate} onChange={setFilterRequestDate} />

        <DateFilter placeholder='Departure date' value={filterDepartureDate} onChange={setFilterDepartureDate} />

        <Dropdown
          className='dropdown'
          options={filtersData?.countries ?? []}
          placeholder='Departure country'
          value={filterDepartureСountry}
          onChange={setFilterDepartureСountry}
        />

        <Dropdown
          className='dropdown'
          options={filtersData?.cities ?? []}
          placeholder='Departure city'
          value={filterDepartureCity}
          onChange={setFilterDepartureCity}
        />

        <span className='reset' onClick={clearFilters}>
          Reset filters
        </span>

        <Button
          className='btn-export-data'
          disabled={exportsLoading}
          text='Export Data'
          variant='submit'
          onClick={() => {
            getExports();
          }}
        />
      </section>

      <h2>To Be processed</h2>

      <section className='table'>
        <Table
          columns={columns}
          data={bookingNotProcessed}
          isLoading={isBookingNotProcessedLoading}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) => (
              <div className={expanded ? 'icon-expanded' : 'icon-expand'} onClick={(e) => onExpand(record, e)}>
                <IconExpand />
              </div>
            ),
            expandedRowRender: (record: IBookingToTable) =>
              record.routes?.length ? <BookingLegs data={record.routes} /> : null,
            rowExpandable: (record: IBookingToTable) => record.routes?.length > 0,
          }}
          expandIconColumnIndex={11}
          variant='dark'
          onChangeColumnOrder={setBookingNotProcessedColumnOrder}
        />
      </section>

      {bookingNotProcessedTotalItems > 10 ? (
        <Pagination
          className='pagination'
          selectedPage={bookingNotProcessedSelectedPage}
          showJumper={true}
          showPageSize={true}
          totalPages={bookingNotProcessedTotalItems}
          variant='dark'
          onPageChange={setBookingNotProcessedSelectedPage}
          onPageSizeChange={setBookingNotProcessedPageSize}
        />
      ) : null}

      <h2>Processed</h2>

      <section className='table'>
        <Table
          columns={columns}
          data={bookingProcessed}
          isLoading={isBookingProcessedLoading}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
              record.routes?.length > 0 ? (
                <div className={expanded ? 'icon-expanded' : 'icon-expand'} onClick={(e) => onExpand(record, e)}>
                  <IconExpand />
                </div>
              ) : null,
            expandedRowRender: (record: IBookingToTable) =>
              record.routes?.length > 0 ? <BookingLegs data={record.routes} /> : null,
            rowExpandable: (record: IBookingToTable) => record.routes?.length > 0,
          }}
          expandIconColumnIndex={11}
          variant='dark'
          onChangeColumnOrder={setBookingProcessedColumnOrder}
        />
      </section>

      {bookingProcessedTotalItems > 10 ? (
        <Pagination
          className='pagination'
          selectedPage={bookingProcessedSelectedPage}
          showJumper={true}
          showPageSize={true}
          totalPages={bookingProcessedTotalItems}
          variant='dark'
          onPageChange={setBookingProcessedSelectedPage}
          onPageSizeChange={setBookingProcessedPageSize}
        />
      ) : null}
    </main>
  );
};

export default BookingListPage;
