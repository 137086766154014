import React from 'react';
import { Spin } from 'antd';
import './styles.scss';

const CallbackPage: React.FC = () => {
  return (
    <main className='page-callback'>
      <Spin tip='Loading...' size='large' spinning></Spin>
    </main>
  );
};

export default CallbackPage;
