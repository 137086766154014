import React, { FC, SyntheticEvent } from 'react';
import { FieldState, Input, useFormApi } from 'informed';
import './styles.scss';

export interface IInputField {
  className?: string;
  disabled?: boolean;
  initialValue?: string;
  label?: string | JSX.Element;
  max?: string;
  min?: string;
  name: string;
  placeholder?: string;
  onChange?: ((fieldState: FieldState, event: SyntheticEvent<Element, Event>) => void) | undefined;
  onBlur?: ((fieldState: FieldState, event: SyntheticEvent<Element, Event>) => void) | undefined;
  validate?: (value: unknown, values: Record<string, unknown>) => unknown;
}

const InputField: FC<IInputField> = ({ className, label, name, ...props }) => {
  const formApi = useFormApi();
  const error = formApi.getError(name);

  return (
    <div className={className ? `input-field ${className}` : 'input-field'}>
      {label && (
        <label className='input-label' htmlFor={name}>
          {label}
        </label>
      )}
      <Input name={name} showErrorIfTouched={false} validateOn='blur' {...props} />
      {error ? <p className='input-error'>{error as string}</p> : null}
    </div>
  );
};

export default InputField;
