import React, { FC } from 'react';
import { Form, FormStateAccessor } from 'informed';
import { Button } from 'hbh-ui-modules';
import { message } from 'antd';
import moment from 'moment';
import './styles.scss';

import { requiredField } from '@common/utils';
import { IBookingRoute, IBookingUpdate, IDropdownOption } from '@common/interfaces';
import { DateField, Dropdown, InputField, Switch } from '@components';

const TIME_INTERVAL = 30;

export interface IFormState {
  arrival_city: string;
  arrival_country: string;
  arrival_date: Date;
  arrival_time: Date;
  departure_city: string;
  departure_country: string;
  departure_date: Date;
  departure_time: Date;
  managed_by_admin: boolean;
  travel_type: IDropdownOption;
}

export interface IBookingEdit {
  route: IBookingRoute;
  onSubmit?: (data: IBookingUpdate) => void;
}

const BookingEdit: FC<IBookingEdit> = ({ route, onSubmit }) => {
  const {
    arrival_city,
    arrival_country,
    arrival_date,
    arrival_time,
    departure_city,
    departure_country,
    departure_date,
    departure_time,
    managed_by_admin,
    order,
    travel_type,
  } = route;

  const initialState = {
    arrival_city,
    arrival_country,
    arrival_date,
    arrival_time,
    departure_city,
    departure_country,
    departure_date,
    managed_by_admin,
    departure_time,
  };

  const optionsTravelType: IDropdownOption[] = [
    { label: 'In-country', value: 'IC' },
    { label: 'International', value: 'IN' },
  ];

  const onFormSubmit = (formState: Record<string, unknown>) => {
    const state = { ...(formState.values as IFormState) };

    if (route) {
      const arrivalDate = moment(state.arrival_date).format('YYYY-MM-DD');
      const arrivalTime = moment(state.arrival_time).format('HH:mm');
      const departureDate = moment(state.departure_date).format('YYYY-MM-DD');
      const departureTime = moment(state.departure_time).format('HH:mm');

      const arrivalFullDate = moment(`${arrivalDate} ${arrivalTime}`, 'YYYY-MM-DD HH:mm');
      const departureFullDate = moment(`${departureDate} ${departureTime}`, 'YYYY-MM-DD HH:mm');

      if (moment(state.arrival_date).isBefore(state.departure_date)) {
        message.error('Please select a valid date');
      } else if (moment(arrivalFullDate).isSameOrBefore(departureFullDate)) {
        message.error('Please select a valid time');
      } else {
        onSubmit &&
          onSubmit({
            routes: [
              {
                arrival_city: state.arrival_city,
                arrival_country: state.arrival_country,
                arrival_date: arrivalDate,
                arrival_time: arrivalTime,
                departure_city: state.departure_city,
                departure_country: state.departure_country,
                departure_date: departureDate,
                departure_time: departureTime,
                managed_by_admin: state.managed_by_admin,
                order,
                travel_type: state.travel_type.value,
              },
            ],
          });
      }
    }
  };

  return (
    <Form className='form' initialValues={initialState} onSubmit={onFormSubmit}>
      <FormStateAccessor>
        {(formState) => (
          <>
            <div className='row'>
              <DateField
                className='field'
                defaultValue={moment(departure_date, 'DD/MM/YYYY').toDate()}
                label='Departure Date'
                name='departure_date'
                validate={requiredField}
              />
              <DateField
                className='field'
                dateFormat='HH:mm'
                defaultValue={moment(departure_time, 'HH:mm:ss').toDate()}
                label='Departure Time'
                name='departure_time'
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={TIME_INTERVAL}
                timeFormat='HH:mm'
                validate={requiredField}
              />
              <InputField
                className='field'
                label='Departure Country'
                name='departure_country'
                validate={requiredField}
              />
              <InputField className='field' label='Departure City' name='departure_city' validate={requiredField} />
            </div>
            <div className='row'>
              <DateField
                className='field'
                defaultValue={moment(arrival_date, 'DD/MM/YYYY').toDate()}
                label='Arrival Date'
                name='arrival_date'
                validate={requiredField}
              />
              <DateField
                className='field'
                dateFormat='HH:mm'
                defaultValue={moment(arrival_time, 'HH:mm:ss').toDate()}
                label='Arrival Time'
                name='arrival_time'
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={TIME_INTERVAL}
                timeFormat='HH:mm'
                validate={requiredField}
              />
              <InputField className='field' label='Arrival Country' name='arrival_country' validate={requiredField} />
              <InputField className='field' label='Arrival City' name='arrival_city' validate={requiredField} />
            </div>
            <div className='row'>
              <Dropdown
                className='field'
                defaultValue={optionsTravelType.find((i) => i.value === travel_type)}
                label='Travel Type'
                name='travel_type'
                options={optionsTravelType}
              />
              <Switch
                className='field transport-support'
                defaultValue={managed_by_admin}
                label='Transport Support'
                name='managed_by_admin'
                text={formState.values.managed_by_admin ? 'Yes' : 'No'}
              />
              <Button className='btn-submit' text='Confirm' type='submit' variant='submit' />
            </div>
          </>
        )}
      </FormStateAccessor>
    </Form>
  );
};

export default BookingEdit;
