import React, { FC } from 'react';
import { useAuth } from 'hbh-ui-modules';
import { SUPPORT_EMAIL } from '@common/constants';

const ErrorPage: FC = () => {
  const { error } = useAuth();

  return (
    <main className='page-error'>
      <div className='container'>
        <h3 className='message'>
          Ops, there was an error: {error ?? 'Server error'}. If you keep getting this error please contact support:{' '}
          <a href={`mailto: ${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        </h3>
      </div>
    </main>
  );
};

export default ErrorPage;
