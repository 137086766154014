/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import Select, { components, Props as ReactSelectProps } from 'react-select';
import { useField } from 'informed';
import './styles.scss';

import { IDropdownOption } from '@common/interfaces';

import { ReactComponent as CloseRoundIcon } from '@assets/svg/icon_close_round.svg';
import { ReactComponent as DropdownArrowIcon } from '@assets/svg/icon_dropdown_arrow.svg';

export const getDropdownComponents = (isClearable?: boolean) => {
  const ClearIndicator = (props: any): JSX.Element => (
    <components.ClearIndicator {...props}>
      <CloseRoundIcon />
    </components.ClearIndicator>
  );

  const DropdownIndicator = (props: any): JSX.Element => (
    <components.DropdownIndicator {...props}>
      <DropdownArrowIcon />
    </components.DropdownIndicator>
  );

  const IndicatorSeparator = isClearable ? components.IndicatorSeparator : null;

  return {
    classNamePrefix: 'rs',
    components: { ClearIndicator, DropdownIndicator, IndicatorSeparator },
  };
};

export interface IDropdown extends Omit<ReactSelectProps, 'onChange'> {
  className?: string;
  defaultValue?: IDropdownOption;
  isDisabled?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  label?: string | JSX.Element;
  name: string;
  options?: IDropdownOption[];
  onChange?: (value: IDropdownOption) => void;
  validate?: (value: unknown, values: any) => void | unknown;
}

const Dropdown: FC<IDropdown> = ({
  className,
  defaultValue,
  isClearable,
  label,
  name,
  onChange,
  validate,
  ...rest
}) => {
  const {
    fieldState: { error, showError, value },
    fieldApi: { setValue, setTouched, validate: validateField },
    render,
    ref,
  } = useField<Partial<IDropdown>, IDropdownOption>({
    defaultValue,
    name,
    showErrorIfError: true,
    type: 'select',
    validate,
  });

  return render(
    <div className={className ? `select ${className}` : 'select'}>
      {label && <label className='select-label'>{label}</label>}
      <Select
        ref={ref}
        value={value ? value : null}
        onChange={(option: any) => {
          setValue(option);
          onChange?.(option);
          setTouched(true);
          validateField();
        }}
        {...getDropdownComponents(isClearable)}
        {...rest}
      />
      {showError ? <p className='select-error'>{error as string}</p> : null}
    </div>
  );
};

export default Dropdown;
