import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const NotFoundPage: FC = () => {
  return (
    <main className='page-not-found'>
      <div className='container'>
        <h1>Sorry, you don&apos;t have permission to access this section at this time</h1>
        <hr />
        <p>
          You may want to head back to the homepage. <br /> If you think something is broken, contact us. (404)
        </p>

        <div className='links'>
          <Link className='link' to='/'>
            Go to Homepage
          </Link>
          <a className='link' href='mailto:noreply.humanitarianbooking@wfp.org'>
            Contact us
          </a>
        </div>
      </div>
    </main>
  );
};

export default NotFoundPage;
