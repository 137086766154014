import { ICrumb } from 'hbh-ui-modules';
import { BookingDetailsPage, BookingListPage, MainMenuPage } from '@pages';
import backgroundImage from '@assets/img/background.jpg';

export default [
  {
    path: '/',
    Component: MainMenuPage,
    isBackgroundImage: true,
    backgroundImage,
  },
  {
    path: '/requests',
    name: 'Travel Requests',
    Component: BookingListPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
  {
    path: '/requests/:id',
    name: 'Travel Request details',
    Component: BookingDetailsPage,
    isBackgroundImage: true,
    isNavigation: true,
    backgroundImage,
  },
] as ICrumb[];
