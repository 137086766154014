import React, { FC, memo } from 'react';
import { IBookingRoute } from '@common/interfaces';
import { Table } from 'hbh-ui-modules';
import './styles.scss';

const columns = [
  {
    dataIndex: 'leg_number',
    key: 'leg_number',
    title: 'Leg number',
  },
  {
    dataIndex: 'name',
    key: 'name',
    title: 'Name',
  },
  {
    dataIndex: 'last_name',
    key: 'last_name',
    title: 'Last Name',
  },
  {
    dataIndex: 'travel_type',
    key: 'travel_type',
    title: 'Travel Type',
  },
  {
    dataIndex: 'departure_country',
    key: 'departure_country',
    title: 'Departure Country',
  },
  {
    dataIndex: 'departure_city',
    key: 'departure_city',
    title: 'Departure City',
  },
  {
    dataIndex: 'departure_date',
    key: 'departure_date',
    title: 'Departure Date',
  },
  {
    dataIndex: 'arrival_country',
    key: 'arrival_country',
    title: 'Arrival Country',
  },
  {
    dataIndex: 'arrival_city',
    key: 'arrival_city',
    title: 'Arrival City',
  },
  {
    dataIndex: 'arrival_date',
    key: 'arrival_date',
    title: 'Arrival Date',
  },
  {
    dataIndex: 'managed_by_admin',
    key: 'managed_by_admin',
    title: 'Transport Support',
    render: (record: boolean) => (record ? 'Yes' : 'No'),
  },
  {
    key: 'status',
  },
];

interface IBookingLegs {
  data: IBookingRoute[];
}

export const BookingLegs: FC<IBookingLegs> = ({ data }) => {
  return <Table columns={columns} data={data} variant='dark' />;
};

export default memo(BookingLegs);
