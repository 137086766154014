import { TAuthConfig } from "hbh-ui-modules";

const authConfig: TAuthConfig = {
  authorizationEndpoint: process.env.REACT_APP_AUTH_AUTHORIZATION_ENDPOINT ?? '',
  autoAuthorize: false,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? '',
  logoutEndpoint: process.env.REACT_APP_AUTH_LOGOUT_ENDPOINT ?? '',
  logoutUri: process.env.REACT_APP_AUTH_LOGOUT_URI ?? '',
  permissionsEndpoint: '',
  redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI ?? '',
  refreshTokenErrorCode: Number(process.env.REACT_APP_AZURE_REFRESH_TOKEN_ERROR_CODE),
  scope: ['email', 'openid', 'profile'].join(' '),
  tokenEndpoint: process.env.REACT_APP_AUTH_TOKEN_ENDPOINT ?? ''
}

export default authConfig;