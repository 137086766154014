import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useParamsParse = (): boolean => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const agency = query.get('agency');
  const facility = query.get('facility_id');

  useEffect(() => {
    if (agency && facility) {
      localStorage.setItem('agency', agency);
      localStorage.setItem('facility', facility);
    }
  }, [agency, facility]);

  return !!(agency && facility);
};

export default useParamsParse;
